import React, { useState } from "react";
import "./Contacts.css";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";
import contact1 from "../../assets/icons/contact1.webp";
import contact2 from "../../assets/icons/contact2.webp";
import MobileMenu from "../MobileMenu/MobileMenu";
import PopupContacts from "../PopupContacts/PopupContacts";
import axios from "axios";
import Loader from "../utils/Loader/Loader";

const Contacts = (props) => {
  const [menu, setMenu] = useState(false);
  const [popupClose, setClosePopup] = useState(false);
  const [firstName, setFirtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState(false);
  const [loader, setLoader] = useState(false);
  const postMessages = () => {
    setLoader(true);
    const mess = `First name: ${firstName},\nLast name: ${lastName},\nemail: ${email},\nphone: ${phone},\nmessage: ${message}`;
    axios
      .post(
        "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmail",
        {
          message: mess,
          subject: "Contacts",
        }
      )
      .then((data) => {
        setLoader(false);
      })
      .catch((err) => alert(err));
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header
        setMenu={setMenu}
        activeTitle={"Contact"}
        setClosePopup={setClosePopup}
        setSubject={setSubject}
      />
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={subject} />
      )}
      {menu && <MobileMenu setMenu={setMenu} />}
      <div className="contact_heater-text">
        Tell your story
        <span className="contact_heater-text-purple"> with Pixomnia</span>
      </div>
      <div className="contact_data1-block">
        <div className="contact_data1-cont">
          <div className="contact_img-cont">
            <img className="contact_img1" alt="img" src={contact1} />
          </div>
        </div>
        <div className="contact_data1-text-cont">
          <div className="contact_data1-text-header">Our Mission</div>
          <div className="contact_data1-text">
            At Pixomnia, our mission is to empower businesses and individuals to
            leverage the power of visual content to communicate, engage, and
            inspire their audience. We believe that every business has a unique
            story to tell, and we strive to provide them with the tools and
            expertise they need to tell that story effectively through stunning
            visuals.
          </div>
        </div>
      </div>
      <div className="contact_data1-block">
        <div className="contact_data1-text-cont">
          <div className="contact_data1-text">
            Our goal is to make visual content creation accessible and
            affordable for all, while maintaining the highest standards of
            quality and creativity. We are committed to staying at the forefront
            of innovation in visual content creation, and to continuously
            improving our services to meet the evolving needs of our clients.
          </div>
        </div>
        <div className="contact_data1-cont">
          <div className="contact_img-cont">
            <img className="contact_img1 block2" alt="img" src={contact2} />
          </div>
        </div>
      </div>

      <div className="contact_heater-text-two">Contact Pixomnia</div>
      <div className="contact_heater-text-desc">
        Drop us a line with any questions, inquiries or business proposals
      </div>
      <div className="job_inputs-cont">
        <div className="job_input-cont">
          <div className="job_input-placeholder">First name</div>
          <input
            className="job_input"
            aria-label="firstname"
            value={firstName}
            onChange={(e) => setFirtName(e.target.value)}
          />
        </div>
        <div className="job_input-cont">
          <div className="job_input-placeholder">Last name</div>
          <input
            className="job_input"
            aria-label="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="job_inputs-cont">
        <div className="job_input-cont">
          <div className="job_input-placeholder">Email</div>
          <input
            className="job_input"
            aria-label="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="job_input-cont">
          <div className="job_input-placeholder">Phone</div>
          <input
            className="job_input"
            aria-label="phone"
            type="number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
      {/* <div className="jobs_text-area-cont">
        <div>
          <div className="job_text-area-placeholder">Phone</div>
          <input className="job_text-area" aria-label="phone" />
        </div>
      </div> */}
      <div className="jobe_resume-header">Leave us a message...</div>
      <div className="jobe_resume-cont">
        <input
          className="jobe_resume-input"
          aria-label="resume"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      <div className="contact_btn-cont">
        <div className="contact_btn" onClick={postMessages}>
          {loader ? <Loader /> : "Submit"}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contacts;
