import React, { useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import "./HomePage.css";
import center from "../../assets/icons/center.webp";
import Footer from "../Footer.js/Footer";
import MobileMenu from "../MobileMenu/MobileMenu";
import ScreenImg1 from "../../assets/icons/screenImg1.webp";
import ScreenImg2 from "../../assets/icons/screenImg2.webp";
import ScreenImg3 from "../../assets/icons/screenImg3.webp";
import tube from "../../assets/icons/tubeW.svg";
import twitterW from "../../assets/icons/twitterW.svg";
import tiktokW from "../../assets/icons/tiktokW.svg";
import fbW from "../../assets/icons/fbW.svg";
import instW from "../../assets/icons/instW.svg";
import videoRight from "../../assets/icons/rightVideo.webm";
import videoLeft from "../../assets/icons/leftVideo.webm";
import videoBlock from "../../assets/icons/videoBlock.jpg";
import stationImg from "../../assets/icons/station.png";
import budjetImg from "../../assets/icons/budjet-img.png";
import promo1 from "../../assets/icons/promo1.mp4";
import promo2 from "../../assets/icons/promo2.mp4";
import promo3 from "../../assets/icons/promo3.mp4";

import confirm from "../../assets/icons/confirm.webp";
import videoPopup from "../../assets/icons/videoBlock.mp4";
import closePopup from "../../assets/icons/closePopup.svg";
import PopupContacts from "../PopupContacts/PopupContacts";
import partners from "../../assets/icons/partners.svg";
import VisibilitySensor from "react-visibility-sensor";
import play from "../../assets/icons/play.svg";
import purpleLine from "../../assets/icons/purpleLine.svg";
import { useNavigate, useSearchParams } from "react-router-dom";

const HomePage = (props) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [menu, setMenu] = useState(false);
  const [popup, setPopup] = useState(false);
  const [popupClose, setClosePopup] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [index, setIndex] = useState(0);
  const [hoveredLeft, setHoveredLeft] = useState(false);
  const [hoveredRight, setHoveredRight] = useState(false);
  const [subject, setSubject] = useState(false);
  const [whuPix, setWhyPix] = useState(false);
  const navigation = useNavigate();
  const toLink = (link) => {
    navigation(`/${link}`);
  };
  const [data, setData] = useState([
    {
      active: false,
      title: "Save Time",
      desc: "At our company, we understand that time is a valuable resource. That’s why our services are designed to help you save time by taking care of experienced professionals, you can focus on other aspects of your business while we handle your social media presence.",
    },
    {
      active: false,
      title: "Generate More Quality Content",
      desc: "With our services, you can expect to receive a constant stream of high-quality content across all major social media platforms, including Instagram, Twitter, TikTok, Facebook and more. Whether you're looking to increase your followers, boost engagement, or simply maintain a consistent online presence, we've got you covered.",
    },
    {
      active: false,
      title: "Cultivate Regulars",
      desc: "Our rewards program drives repeat business while also helping to strengthen customer relationships and build brand loyalty.Byshowing your customers that you value their business and appreciate their loyalty, you’ll create a positive impression that will keep them coming back time and time again. ",
    },
  ]);

  const handleMouseEnter = (type) => {
    if (type === "left") {
      setHoveredLeft(true);
    } else {
      setHoveredRight(true);
    }
  };

  const handleMouseLeave = (type) => {
    if (type === "left") {
      setHoveredLeft(false);
    } else {
      setHoveredRight(false);
    }
  };
  const showPopup = () => {
    setSubject("Book a demo");
    setClosePopup(true);
  };
  useEffect(() => {
    const param = searchParams.get("contact");
    if (param) {
      setSubject("Book a demo");
      setClosePopup(true);
    }
  }, [setSubject, setClosePopup]);
  const comments = [
    {
      header:
        "A significant increase in our social media presence and engagemen...",
      desc: "I can't speak highly enough of Pixomnia's services. From the moment we started working with them, their team was professional, efficient, and truly dedicated to helping us enhance our customers' dining experiences. The complimentary postcard service they offer has been a hit with our guests, and it's so easy to use. The postcards are beautifully designed and feature our restaurant's corporate style, adding a touch of elegance to the guests' keepsake. Their content creators are highly trained and really know how to capture the essence of our food and dining atmosphere. We've seen a significant increase in our social media presence and engagement since partnering with Pixomnia, which has been a game-changer for our marketing efforts. Overall, I highly recommend Pixomnia to any restaurant looking to elevate their customer service and marketing strategies",
      position: "Co-Owner Le Grand Restaurant, Los Angeles CA",
    },
    {
      header: "'Our guests love the complimentary postcards...'",
      desc: "As the general manager of Takami sushi and elevate lounge, I highly recommend Pixomnia's services to any restaurant looking to enhance their guests' dining experience. Our partnership with Pixomnia has not only provided our guests with a unique and memorable memento of their visit, but it has also boosted our social media presence and engagement. The Pixomnia content creators are professional, friendly, and efficient. Our guests love the complimentary postcards and often share them on social media, tagging us in their posts and generating free publicity for our establishment. I highly recommend Pixomnia's services to any restaurant looking to enhance their guests' dining experience and boost their social media presence.",
      position: "General Manager Takami sushi restaurant and Elevate lounge.",
    },
    {
      header: "'Strong social media presence and customer loyalty'",
      desc: "As the owner of Pardis in Glendale Los Angeles, I have had the pleasure of working with Pixomnia, and I have to say that I am extremely impressed with their services. Pixomnia has been instrumental in helping us to create a strong social media presence and build customer loyalty through their innovative programs.",
      position: "General Manager Pardis restaurant and Elevate lounge.",
    },
  ];

  const videoRef = useRef(null);
  const targetRef = useRef(null);
  const handleClick = () => {
    if (targetRef.current) {
      window.scrollTo({
        top: targetRef.current.offsetTop - 130,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    playVideo();
  }, []);
  const onChangeVisibility = (isVisible) => {
    setIsVisible(isVisible);
  };
  const changeIndex = () => {
    if (index !== comments.length - 1) {
      setIndex(index + 1);
    }
  };
  const changeIndexMinus = () => {
    if (index !== 0) {
      setIndex(index - 1);
    }
  };
  const changeItem = (key) => {
    console.log(key);
    const newData = [...data];
    let falseActive = newData.map((item) => {
      return { ...item, active: false };
    });
    if (falseActive[key].active === false) {
      console.log("ok");
      falseActive[key].active = true;
    } else {
      falseActive[key].active = false;
    }

    setData(falseActive);
  };
  return (
    <div className="wrapper">
      <Header
        setMenu={setMenu}
        setClosePopup={setClosePopup}
        setSubject={setSubject}
      />
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={subject} />
      )}

      {popup && (
        <div className="popup-video-cont">
          <div className="popup_close">
            <img
              alt="close"
              className="close_popup"
              src={closePopup}
              onClick={() => setPopup(false)}
            />
          </div>
          <video
            className="popup-video-item"
            ref={videoRef}
            autoPlay={true}
            muted={true}
            loop={true}
            controls={true}
            playsInline={true}
          >
            <source src={videoPopup} type="video/mp4" />
          </video>
        </div>
      )}

      {menu && <MobileMenu setMenu={setMenu} />}
      <div className="home_h-text">Unleash your marketing potential.</div>
      <div className="home_h-text-mobile">
        Unleash your <br />
        marketing potential.
      </div>
      <div className="home_desc-text-mobile">
        Boost social media presence.
        <br />
        Enhance the dining experience and bring additional value.
        <br />
        Supercharge your promotions.
      </div>
      {/* <div className="home-login-mobile-btn">Log in</div> */}
      <div className="home-btn-cont-mobile" onClick={showPopup}>
        <div className="home-btn-mobile">Get started</div>
      </div>
      <div className="home_sections-block-mobile">
        <div className="home_sections-header">Sections</div>
        <div className="home_section-cont">
          <div
            className="home_section-cont-link"
            onClick={() => toLink("features")}
          >
            Features
          </div>
          <div
            className="home_section-cont-link"
            onClick={() => toLink("price")}
          >
            Pricing
          </div>
          <div
            className="home_section-cont-link"
            onClick={() => toLink("jobs")}
          >
            Jobs
          </div>
          <div
            className="home_section-cont-link"
            onClick={() => toLink("contacts")}
          >
            Contact
          </div>
        </div>
      </div>
      {/* 
      <div
        style={{
          width: "80%",
          height: "80vh",
          border: "1px solid red",
          position: "fixed",
          top: 130,
          left: "10%",
          zIndex: 9999,
        }}
      ></div> */}
      <div className="video_video-block">
        <div className="video_video-cont">
          <div className="video_video-text side">
            Boost social media presence.
          </div>
          <video
            className="video_video-item"
            ref={videoRef}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={videoRight} type="video/webm" />
          </video>
        </div>
        <div className="video_video-center-cont">
          <div className="video_video-text center">
            Enhance the dining experience and bring additional value.
          </div>
          <img alt="img" src={center} className="video_video-item-center" />
        </div>
        <div className="video_video-cont left">
          <div className="video_video-text side">
            Supercharge your promotions.
          </div>
          <video
            className="video_video-item"
            ref={videoRef}
            autoPlay={true}
            muted={true}
            loop={true}
          >
            <source src={videoLeft} type="video/webm" />
          </video>
        </div>
      </div>
      <div className="home_more-cont">
        <div className="home_more-btn" onClick={handleClick}>
          Learn more
        </div>
      </div>
      <div className="home_partners-cont">
        <div className="home_partners-text">
          Trusted by dozens of businesses
        </div>
        <div className="home_partners-block">
          <img
            src={partners}
            className="home_partners-block-img"
            alt="partners"
          />
        </div>
      </div>

      <div className="home_screen-block">
        <div className="home_screen-cont">
          <div className="home_screen-imgs-conts">
            <div className="home_gradient-cont">
              <div className="home_gradient-text">
                Create a memorable experience for your guests <br /> by
                providing them with an interactive photo souvenir that doubles
                as a smart promotional flyer.
              </div>
            </div>
            <div className="home_screen-imgs-cont">
              <div className="home_screen-img-cont">
                <img
                  alt="screen"
                  src={ScreenImg1}
                  className="home_screen-img"
                />
                <div className="home_screen-text">
                  Memories worth sharing: elevate your brand and stand out on
                  social media and beyond.
                </div>
              </div>
              <div className="home_screen-img-cont">
                <img
                  alt="screen"
                  src={ScreenImg2}
                  className="home_screen-img"
                />
                <div className="home_screen-text">
                  Generate unique content with our interactive physical photo
                  souvenir program.
                </div>
              </div>
            </div>
          </div>
          <div className="home_screen-right-screen">
            <img
              className="home_screen-img-right"
              alt="screen"
              src={ScreenImg3}
            />
            <div className="home_screen-text">
              Reach out new audience with our social commerce solutions.
            </div>
          </div>
        </div>
      </div>
      <VisibilitySensor onChange={onChangeVisibility} partialVisibility>
        <div
          className={isVisible ? "home_prev-block fade-in" : "home_prev-block"}
          ref={targetRef}
        >
          <div
            className={
              isVisible ? "home_prev-header fade-in-header" : "home_prev-header"
            }
          >
            A New Era for social Media Marketing.
          </div>
          <div
            className={
              isVisible ? "home_prev-desc fade-in-header" : "home_prev-desc"
            }
          >
            A one-stop solution that delivers your targeted content across all
            platforms.
          </div>
          <div className="home_prev-all-cont">
            <div className="home_prev-social-cont">
              <div className="home_prev-social-block">
                <div className="home_prev-social-rectangle">
                  <img
                    alt="soc"
                    src={fbW}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>
              <div className="home_prev-social-block">
                <div className="home_prev-social-rectangle">
                  <img
                    alt="soc"
                    src={instW}
                    className="home_rectangle-soc-img"
                  />
                </div>
              </div>
              <div className="home_prev-social-block">
                <div className="home_prev-social-rectangle">
                  <img
                    alt="soc"
                    src={tube}
                    className="home_rectangle-soc-img"
                  />
                </div>
              </div>
              <div className="home_prev-social-block">
                <div className="home_prev-social-rectangle">
                  <img
                    alt="soc"
                    src={twitterW}
                    className="home_rectangle-soc-img"
                  />
                </div>
              </div>
              <div className="home_prev-social-block">
                <div className="home_prev-social-rectangle">
                  <img
                    alt="soc"
                    src={tiktokW}
                    className="home_rectangle-soc-img"
                  />
                </div>
              </div>
              <div className="home_prev-social-vertical-line"></div>
              <div className="home_prev-social-horizontal-line"></div>
              <div
                className={
                  isVisible
                    ? "home_prev-heden-social-block fade-social"
                    : "home_prev-heden-social-block"
                }
              ></div>
            </div>
            <div className="home_prev-img-cont" onClick={() => setPopup(true)}>
              <img
                alt="play"
                src={play}
                className={
                  isVisible
                    ? "home_prev-img-play fade-in-img"
                    : "home_prev-img-play"
                }
              />
              <img
                alt="img"
                src={videoBlock}
                className={
                  isVisible ? "home_prev-img fade-in-img" : "home_prev-img"
                }
              />
            </div>
          </div>
          <div className="home_prev-all-cont-mob">
            <div className="home_prev-all-social-cont">
              <div className="home_horizontalline-mob"></div>
              <div className="home_vertical-line-mob"></div>
              <div className="home_prev-all-social-black-mob">
                <div className="home_prev-all-social-block-mob">
                  <img
                    alt="soc"
                    src={fbW}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>

              <div className="home_prev-all-social-black-mob">
                <div className="home_prev-all-social-block-mob">
                  <img
                    alt="soc"
                    src={instW}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>
              <div className="home_prev-all-social-black-mob">
                <div className="home_prev-all-social-block-mob">
                  <img
                    alt="soc"
                    src={tube}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>
              <div className="home_prev-all-social-black-mob">
                <div className="home_prev-all-social-block-mob">
                  <img
                    alt="soc"
                    src={twitterW}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>
              <div className="home_prev-all-social-black-mob">
                <div className="home_prev-all-social-block-mob">
                  <img
                    alt="soc"
                    src={tiktokW}
                    className="home_rectangle-soc-img fb"
                  />
                </div>
              </div>
            </div>
            <div className="home_prev-img-cont" onClick={() => setPopup(true)}>
              <img
                alt="play"
                src={play}
                className={
                  isVisible ? "home_prev-img-play " : "home_prev-img-play"
                }
              />
              <img
                alt="img"
                src={videoBlock}
                className={isVisible ? "home_prev-img " : "home_prev-img"}
              />
            </div>
          </div>
          <div className="home_desc-video-cont-hidden-block-rel">
            <div className="home_desc-video-cont-hidden-block">
              <div
                className={
                  isVisible
                    ? "home_desc-video-cont-show active"
                    : "home_desc-video-cont-show "
                }
              ></div>
              <div
                className={
                  isVisible
                    ? "home_desc-video-cont-hidden active"
                    : "home_desc-video-cont-hidden"
                }
              ></div>
            </div>

            <div className="home_desc-video-cont">
              <div className="home_desc-video-block">
                <div className="home_desc-video-block-line1"></div>
              </div>
              <div className="home_desc-video-block-line2">
                <div className="home_desc-video-block-line3"></div>
              </div>
            </div>
            <div className="home_desc-video-data-cont">
              <div className="home_desc-video-data-block">
                <div className="home_desc-video-data-block-header-gr"></div>
                <div className="home_desc-video-data-block-header">
                  Save
                  <br /> Time
                </div>
                <div className="home_desc-video-data-block-desc">
                  At our company, we understand that time is a valuable
                  resource. That’s why our services are designed to help you
                  save time by taking care of experienced professionals, you can
                  focus on other aspects of your business while we handle your
                  social media presence.
                </div>
              </div>
              <div className="home_desc-video-data-block">
                <div className="home_desc-video-data-block-header-gr"></div>
                <div className="home_desc-video-data-block-header">
                  Generate More
                  <br />
                  Quality Content
                </div>
                <div className="home_desc-video-data-block-desc">
                  With our services, you can expect to receive a constant stream
                  of high-quality content across all major social media
                  platforms, including Instagram, Twitter, TikTok, Facebook and
                  more. Whether you're looking to increase your followers, boost
                  engagement, or simply maintain a consistent online presence,
                  we've got you covered.
                </div>
              </div>
              <div className="home_desc-video-data-block">
                <div className="home_desc-video-data-block-header-gr"></div>
                <div className="home_desc-video-data-block-header">
                  Cultivate <br /> Regulars
                </div>
                <div className="home_desc-video-data-block-desc">
                  Our rewards program drives repeat business while also helping
                  to strengthen customer relationships and build brand loyalty.
                  By showing your customers that you value their business and
                  appreciate their loyalty, you’ll create a positive impression
                  that will keep them coming back time and time again.
                </div>
              </div>
            </div>
          </div>
          <div className="home_desc-video-cont-hidden-block-rel-mob">
            {data.map((item, key) => (
              <div
                className="home_desc-video-blocks-item"
                key={key}
                onClick={() => changeItem(key)}
              >
                <div className="home_desc-video-blocks-item-header">
                  {item.title}
                </div>
                {item.active && (
                  <div className="home_desc-video-blocks-item-header-desc">
                    {item.desc}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="home_desc-video-blocks-fake-block"></div>
        </div>
      </VisibilitySensor>
      <div className="home_why-pix-cont">
        <div className="home_why-pix-header">Why Pixomnia</div>
        <div className="home_why-pix-desc">
          Put Pixomnia to work.
          <br /> Invest your time where it’s needed.
        </div>
        <div className="home_why-pix-content-cont">
          <div className="home_why-pix-right-content">
            <img
              alt="station"
              src={stationImg}
              className="home_why-pix-right-img"
            />
            <div className="home_why-pix-right-sation-text">
              Pixomnia’s on-site content creators generate complementary
              postcards using our pro app. Our compact printing station, the
              size of a nightstand, ensures that your postcards will be produced
              quickly and efficiently without compromising on quality.
            </div>
            <div
              className={
                whuPix
                  ? "home_why-pix-right-text-cont active"
                  : "home_why-pix-right-text-cont"
              }
            >
              <div
                className={
                  whuPix
                    ? "home_why-pix-right-text active"
                    : "home_why-pix-right-text"
                }
              >
                Pixomnia’s on-site content creators generate complementary
                postcards using our pro app. Our compact printing station, the
                size of a nightstand, ensures that your postcards will be
                produced quickly and efficiently without compromising on
                quality.
              </div>

              <div
                className="home_why-pix-right-text-more"
                onClick={() => setWhyPix(!whuPix)}
              >
                {!whuPix ? "Learn more" : "Close"}
              </div>
            </div>
          </div>
          <div className="home_why-pix-left-cont">
            <div className="home_why-pix-left-cont-header">Easy setup</div>
            <div className="home_why-pix-left-cont-desc">
              Set it up once, and you’re good to go. <br />
              No hassle or contact required.
            </div>
            <div className="home_why-pix-left-text">Our requirements :</div>
            <div className="home_why-pix-left-item-cont">
              <div className="home_why-pix-left-item-num-cont">1</div>
              <div className="home_why-pix-left-item-text">
                A small space for our printing station 22 x 16 x 19 inches
              </div>
            </div>
            <div className="home_why-pix-left-item-cont">
              <div className="home_why-pix-left-item-num-cont">2</div>
              <div className="home_why-pix-left-item-text">
                Electrical Socket type A (120v. 60Hz)
              </div>
            </div>
            <div className="home_why-pix-left-item-cont">
              <div className="home_why-pix-left-item-num-cont">3</div>
              <div className="home_why-pix-left-item-text">
                WI-FI access point (300 Mpbs with 10Mbs upload speed)
              </div>
            </div>
            <div className="btn_cont">
              <div className="home_btn" onClick={showPopup}>
                Book a Demo
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_budjet-block">
        <div className="home_budjet-header">Smart budgeting</div>
        <div className="home_budjet-desc">
          With Pixomnia's budgeting system,you can rest assured your resources
          are spent wisely.
        </div>
        <div className="home_budjet-purple-block-text">
          <div className="home_budjet-purple-left-text">
            Performance-based budgeting
          </div>
          <img className="home_budjet-purple-line" alt="img" src={purpleLine} />
          <div className="home_budjet-purple-right-text">
            Dynamic resources allocation
          </div>
        </div>
        <div className="home_budjet-data-cont">
          <div className="home_budjet-data-line"></div>
          <div className="home_budjet-img-cont">
            <img className="home_budjet-img" alt="budjet" src={budjetImg} />
          </div>
          <div className="home_budjet-text-cont">
            <div className="home_budjet-text-block">
              <div className="home_budjet-text">
                With our personal account management service, you'll have
                complete control over your advertising campaigns on social media
                platforms like Facebook, Instagram, Twitter, and more. You can
                set your own budget and preferences, or simply follow our expert
                guidelines to ensure the best possible results.
              </div>
              <div className="home_budjet-text">
                Whether you're looking to increase brand awareness, drive
                traffic to your website, or generate leads and sales, our team
                of social media marketing experts can help you achieve your
                goals.
              </div>
            </div>
            <div className="home_budjet-btn-cont">
              <div className="home_budjet-btn" onClick={showPopup}>
                Book a demo
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="home_promo-block">
        <div>
          <div className="home_promo-left-block">
            <video
              ref={videoRef}
              autoPlay={true}
              muted={true}
              loop={true}
              className="home_promo-left-promo-video l"
              playsInline={true}
            >
              <source src={promo1} type="video/mp4" />
            </video>
            <video
              ref={videoRef}
              autoPlay={true}
              muted={true}
              loop={true}
              className="home_promo-left-promo-video"
              playsInline={true}
            >
              <source src={promo2} type="video/mp4" />
            </video>
            <video
              ref={videoRef}
              autoPlay={true}
              muted={true}
              loop={true}
              className="home_promo-left-promo-video"
              playsInline={true}
            >
              <source src={promo3} type="video/mp4" />
            </video>
          </div>
          <div className="home_promo-right-btn-cont-mob">
            <div className="home_btn" onClick={showPopup}>
              Book a Demo
            </div>
          </div>
        </div>
        <div className="home_promo-right-block">
          <div className="home_promo-right-header">Beautiful ads templates</div>
          <div className="home_promo-right-desc">
            Our design presets match multiple <br /> platforms and formats.
          </div>
          <div className="home_promo-right-item-cont">
            <div className="home_promo-right-item-block">
              <div className="home_promo-right-item">
                <img
                  className="home_promo-right-item-img"
                  alt="confirm"
                  src={confirm}
                />
                <div className="home_promo-right-item-text">Image ads</div>
              </div>
              <div className="home_promo-right-item">
                <img
                  className="home_promo-right-item-img"
                  alt="confirm"
                  src={confirm}
                />
                <div className="home_promo-right-item-text">Video ads</div>
              </div>
            </div>
            <div className="home_promo-right-item-block">
              <div className="home_promo-right-item">
                <img
                  className="home_promo-right-item-img"
                  alt="confirm"
                  src={confirm}
                />
                <div className="home_promo-right-item-text">Story ads</div>
              </div>
              <div className="home_promo-right-item">
                <img
                  className="home_promo-right-item-img"
                  alt="confirm"
                  src={confirm}
                />
                <div className="home_promo-right-item-text">
                  Interactive ads
                </div>
              </div>
            </div>
          </div>
          <div className="home_promo-right-btn-cont">
            <div className="home_btn" onClick={showPopup}>
              Book a Demo
            </div>
          </div>
        </div>
      </div>
      <div className="home_comment-cont">
        <div className="home_comment-header">{comments[index].header}</div>
        <div className="home_comment-text">{comments[index].desc}</div>
        <div className="home_comment-bottom-cont">
          <div className="home_comment-user-block">
            <div>
              <div className="home_comment-avatar"></div>
            </div>

            <div className="home_comment-user-desc-cont">
              <div className="home_comment-user-desc">
                {comments[index].position}
              </div>
            </div>
          </div>
          <div className="home_comment-arrow-block">
            <div
              className={
                index === 0
                  ? "home_comment-arrow-block-img disabled"
                  : "home_comment-arrow-block-img transform"
              }
              onClick={changeIndexMinus}
            ></div>
            <div
              className={
                index === comments.length - 1
                  ? "home_comment-arrow-block-img disabled-tansform"
                  : "home_comment-arrow-block-img"
              }
              onClick={changeIndex}
            ></div>
            {/* {index === 0 ? (
              <img
                className="home_comment-arrow-enabled"
                alt="arrow"
                src={enabledImg}
                // onMouseLeave={() => handleMouseLeave("left")}
              />
            ) : (
              <img
                // onMouseEnter={() => handleMouseEnter("left")}
                // onMouseLeave={() => handleMouseLeave("left")}
                className="home_comment-arrow-disabled trasform"
                alt="arrow"
                src={disabledImg}
                onClick={changeIndexMinus}
              />
            )} */}
            {/* {index === comments.length - 1 ? (
              <img
                className="home_comment-arrow-enabled transform"
                alt="arrow"
                src={enabledImg}
                // onMouseLeave={() => handleMouseLeave("right")}
              />
            ) : (
              <img
                // onMouseEnter={() => handleMouseEnter("right")}
                // onMouseLeave={() => handleMouseLeave("right")}
                className="home_comment-arrow-disabled "
                alt="arrow"
                src={disabledImg}
                onClick={changeIndex}
              />
            )} */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HomePage;
