import React, { useState } from "react";
import close from "../../assets/icons/cloce.svg";
import call from "../../assets/icons/call.webp";
import message from "../../assets/icons/messag.webp";

const ContactCont = (props) => {
  const [showText, setShowText] = useState(true);
  const callHandler = () => {
    window.open("tel:+18006570107");
  };
  const smsHandler = () => {
    window.open("sms:+18006570107");
  };
  return (
    <div className="landing_contact-cont-mob">
      <div className="landing_contact-block-mob">
        {showText && (
          <>
            <div className="landing_contact-close-block-mob">
              <div
                className="landing_contact-close-img-mob"
                onClick={() => setShowText(false)}
              >
                <img
                  alt="close"
                  src={close}
                  className="landing_contact-close-img-mo"
                />
              </div>
            </div>
            <div className="landing_contact-text-cont-mob">
              <div className="landing_contact-text-mob">
                Speak with a real person, call or text now.
              </div>
            </div>
            <div className="landing_contact-intent-mob"></div>
          </>
        )}

        <div className="landing_contact-btn-cont-mob">
          <div className="landing_contact-btn-mob" onClick={callHandler}>
            <img src={call} className="landing_contact-btn-mob-img call" />
          </div>
          <div className="landing_contact-btn-mob" onClick={smsHandler}>
            <img src={message} className="landing_contact-btn-mob-img" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCont;
