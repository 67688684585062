import React, { useEffect, useRef, useState } from "react";
import videoBlock from "../../assets/icons/videoBlock.jpg";
import tube from "../../assets/icons/tubeW.svg";
import twitterW from "../../assets/icons/twitterW.svg";
import tiktokW from "../../assets/icons/tiktokW.svg";
import fbW from "../../assets/icons/fbW.svg";
import instW from "../../assets/icons/instW.svg";
import more from "../../assets/icons/more.webp";
import play from "../../assets/icons/playLand.svg";
import videoPopup from "../../assets/icons/videoBlock.mp4";

const VideoCont = ({ setClosePopup }) => {
  const [indexActive, setIndexActive] = useState(0);
  const [showVideo, setShowVideo] = useState(false);
  const [flip, setFlip] = useState(false);
  const [textFlip, setTextFlip] = useState(false);
  const [fadeIn, setFadeIn] = useState(false);

  const changeFlip = () => {
    setFlip(true);
    setTextFlip(true);
    setTimeout(() => setFlip(false), 250);
  };
  const closeFlip = () => {
    setFlip(true);
    setTextFlip(false);
    setTimeout(() => setFlip(false), 250);
  };
  const videoRef = useRef(null);
  useEffect(() => {
    const playVideo = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    playVideo();
  }, []);
  const datarectangle = [
    {
      img: fbW,
    },
    {
      img: instW,
    },
    {
      img: tube,
    },
    {
      img: twitterW,
    },
    {
      img: tiktokW,
    },
  ];
  const dataSlider = [
    {
      title: "Reels",
      desc: [
        "30-50% increase in daily uploads",
        "AI video generator  (images into videos)",
        "Consistent uploads",
      ],
      active: false,
      flip: {
        what: [
          { title: "Increase ", class: "landing-video-cont-flip-span-purple" },
          {
            title: "daily Reels ",
            class: "landing-video-cont-flip-span-white",
          },
          { title: "uploads ", class: "landing-video-cont-flip-span-purple" },
          { title: "by ", class: "landing-video-cont-flip-span-white" },
          { title: "30-50% ", class: "landing-video-cont-flip-span-purple" },
          {
            title: "and reach a wider audience ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
        how: [
          {
            title: " The AI editor engine transforms ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "images into dynamic videos ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "and pairs them with popular music. ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
        why: [
          {
            title: "This type of content and ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "consistent daily posting ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "work better with the Instagram algorithm. ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
      },
    },
    {
      title: "Stories",
      desc: [
        "7-10 stories daily",
        "Smart content allocation",
        "Better social commerce results",
      ],
      active: false,
      flip: {
        what: [
          {
            title: "7-10 daily uploads ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "consist of a combination of ",
            class: "landing-video-cont-flip-span-white",
          },
          { title: "Reels ", class: "landing-video-cont-flip-span-purple" },
          { title: "and ", class: "landing-video-cont-flip-span-white" },
          { title: "Posts ", class: "landing-video-cont-flip-span-purple" },
          {
            title: "sharing, as well as ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "2 animated promo flyers. ",
            class: "landing-video-cont-flip-span-purple",
          },
        ],

        how: [
          {
            title: " The AI generates animated  ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: " corporate-style ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "promo flyers daily based on provided settings. ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
        why: [
          {
            title: "The current Instagram algorithm yields ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "better results ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "with this ratio. ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
      },
    },
    {
      title: "POSTS",
      desc: [
        "10-20 posts daily",
        "Organic content from restaurant guests",
        "Improvement in organic search results",
      ],
      active: false,
      flip: {
        what: [
          {
            title: "10-20 daily posts ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title:
              "that showcase the restaurant's ambiance, promoted dishes, and drinks.  ",
            class: "landing-video-cont-flip-span-white",
          },
        ],

        how: [
          {
            title: "The content of restaurant offerings is captured during a  ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "professional session ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "once a year. An on-site content creator takes ",
            class: "landing-video-cont-flip-span-white",
          },
          {
            title: "daily pictures ",
            class: "landing-video-cont-flip-span-purple",
          },
          {
            title: "of restaurant guests. ",
            class: "landing-video-cont-flip-span-white",
          },
        ],

        why: [
          {
            title: "Sustainable source of daily content.  ",
            class: "landing-video-cont-flip-span-white",
          },
        ],
      },
    },
  ];
  const [actyvityList, setActivityList] = useState([
    {
      active: false,
    },
    {
      active: false,
    },
    {
      active: false,
    },
  ]);
  useEffect(() => {
    const newlist = [...actyvityList];
    newlist[indexActive].active = true;
    setActivityList(newlist);
  }, [setActivityList]);
  const changeIndex = (key) => {
    setTextFlip(false);
    const list = [...actyvityList];
    let newList = list.map((item) => {
      return { ...item, active: false };
    });
    newList[key].active = true;
    console.log(newList);
    setIndexActive(key);
    setActivityList(newList);
    setFadeIn(true);
    setTimeout(() => setFadeIn(false), 800);
  };
  return (
    <div className="landing_video-cont">
      <div className="landing_video-cont-header">
        we believe that social media
        <br /> marketing should be free!
      </div>
      <div className="landing_video-cont-desc">...so we made it possible.</div>
      {/* <div className="landing_video-mobile-social-rect-cont">
        {datarectangle.map((item, key) => (
          <div className="landing_video-mobile-social-rect-black">
            <div className="landing_video-mobile-social-rect">
              <img
                src={item.img}
                className="landing_video-rectangle-img"
                alt="rectangle-img"
              />
            </div>
          </div>
        ))}
        <div className="landing_video-mobile-social-rect-line"></div>

        {/* <div className="landing_video-mobile-social-rect-black">
          <div className="landing_video-mobile-social-rect"></div>
        </div>
        <div className="landing_video-mobile-social-rect-black">
          <div className="landing_video-mobile-social-rect"></div>
        </div>
        <div className="landing_video-mobile-social-rect-black">
          <div className="landing_video-mobile-social-rect"></div>
        </div>
        <div className="landing_video-mobile-social-rect-black">
          <div className="landing_video-mobile-social-rect"></div>
        </div> */}
      {/* </div> */}
      <div className="landing_video-data-cont">
        <div className="landing_video-slider-cont">
          <div
            className={
              flip
                ? "landing_video-slider-text-cont flip-vertical-right"
                : "landing_video-slider-text-cont"
            }
          >
            {textFlip ? (
              <div
                className="landing_video-slider-flip-cont"
                onClick={closeFlip}
              >
                <>
                  <div>
                    <div className="landing_video-slider-flip-text-block">
                      What:
                    </div>
                    <div className="landing_video-slider-flip-line"></div>
                    <div className="landing_video-slider-flip-text-cont">
                      {dataSlider[indexActive].flip.what.map((item, key) => (
                        <span className={item.class} key={key}>
                          {item.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
                <>
                  <div>
                    <div className="landing_video-slider-flip-text-block">
                      How:
                    </div>
                    <div className="landing_video-slider-flip-line"></div>
                    <div className="landing_video-slider-flip-text-cont">
                      {dataSlider[indexActive].flip.how.map((item, key) => (
                        <span className={item.class} key={key}>
                          {item.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
                <>
                  <div>
                    <div className="landing_video-slider-flip-text-block">
                      Why:
                    </div>
                    <div className="landing_video-slider-flip-line"></div>
                    <div className="landing_video-slider-flip-text-cont">
                      {dataSlider[indexActive].flip.why.map((item, key) => (
                        <span className={item.class} key={key}>
                          {item.title}
                        </span>
                      ))}
                    </div>
                  </div>
                </>
              </div>
            ) : (
              <div className={fadeIn ? "landing-fadein" : "test"}>
                <div className="landing_video-slider-text-title">
                  {dataSlider[indexActive].title}
                </div>
                <ul className="landing_video-slider-text-desc">
                  {dataSlider[indexActive].desc.map((item, key) => (
                    <li key={key} className="landing_video-slider-text-li">
                      {item}
                    </li>
                  ))}
                </ul>
                <div className="landing_video-slider-text-more">
                  <img
                    alt="more"
                    src={more}
                    className="landing_video-slider-text-more-img"
                    onClick={changeFlip}
                  />
                  <div className="landing_video-slider-text-more-text">
                    flip card to learn more
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="landing_video-slider-acivity-cont">
            <div className="landing_video-slider-activity_btn-cont">
              {actyvityList.map((item, key) => (
                <div
                  className="landing_video-slider-activity_btn-cont-b"
                  onClick={() => changeIndex(key)}
                  key={key}
                >
                  <div
                    className={
                      item.active
                        ? "landing_video-slider-activity_btn active"
                        : "landing_video-slider-activity_btn"
                    }
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {showVideo ? (
          <div className="landing_video-data-block">
            <video
              className="landing_video-prew-img"
              ref={videoRef}
              autoPlay={true}
              // muted={true}
              loop={true}
              controls={true}
              playsInline={true}
            >
              <source src={videoPopup} type="video/mp4" />
            </video>
          </div>
        ) : (
          <div className="landing_video-data-block">
            <div className="landing_video-rectangle-block">
              {datarectangle.map((item, key) => (
                <div className="landing_video-rectangle" key={key}>
                  <img
                    src={item.img}
                    className="landing_video-rectangle-img"
                    alt="rectangle-img"
                  />
                </div>
              ))}
            </div>

            <img
              alt="video"
              src={videoBlock}
              className="landing_video-prew-img"
            />
            <img
              alt="video"
              src={play}
              className="landing_video-play-img"
              onClick={() => setShowVideo(true)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoCont;
