const SET_SHOW_SEND = "SET_SHOW_SEND";

const defaultState = {
  showSendPopup: false,
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SHOW_SEND:
      return {
        ...state,
        showSendPopup: action.payload,
      };
    default:
      return state;
  }
}
export const setShowSend = (data) => ({
  type: SET_SHOW_SEND,
  payload: data,
});
