import React, { useState } from "react";
import logo from "../../assets/icons/logoFooter.webp";
import burgerWhite from "../../assets/icons/burgerWhite.svg";
import { Link } from "react-router-dom";

const Header = ({ setClosePopup, setMenu }) => {
  const [showMenu, setShowMenu] = useState(false);
  return (
    <div className="landing_header-cont">
      <div className="landing_header-block">
        <Link className="landing_header-logo" to="/">
          <img src={logo} className="landing_header-logo-img" alt="logo" />
        </Link>
        {showMenu && (
          <div className="landing_header-link-cont">
            <div>
              <a
                className="landing_header-link"
                href="https://pixomniapromo.web.app"
                target="_blank"
                rel="noopener noreferrer"
              >
                Custom Web Apps
              </a>
            </div>
            <div>
              <Link className="landing_header-link" to="/features">
                More Features
              </Link>
            </div>
            {/* <div>
              <Link className="landing_header-link" to="/pricing">
                Pricing
              </Link>
            </div> */}
            <div>
              <Link className="landing_header-link" to="/jobs">
                Jobs
              </Link>
            </div>
            <div>
              <Link className="landing_header-link" to="/contacts">
                Contact
              </Link>
            </div>
          </div>
        )}

        <div className="landing_header-btnCont">
          <div
            className="landing_header-burger-mob"
            onClick={() => setMenu(true)}
          >
            <img
              className="landing_header-burger-btn"
              alt="burger"
              src={burgerWhite}
            />
          </div>
          <div
            className="landing_header-burger-desc"
            onClick={() => setShowMenu(!showMenu)}
          >
            <img
              className="landing_header-burger-btn"
              alt="burger"
              src={burgerWhite}
            />
          </div>

          <div
            className="landing_action-btn"
            onClick={() => setClosePopup(true)}
          >
            BOOK DEMO
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
