import React, { useState } from "react";
import "./Price.css";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";
import MobileMenu from "../MobileMenu/MobileMenu";
import priceConfirm from "../../assets/icons/priceConfirm.svg";
import star from "../../assets/icons/star.svg";
import PopupContacts from "../PopupContacts/PopupContacts";

const Price = (props) => {
  const [menu, setMenu] = useState(false);
  const [popupClose, setClosePopup] = useState(false);
  const [subject, setSubject] = useState(false);
  const data = [
    {
      title: "Basic",
      price: "500",
      date: "Every month",
      desc: "Industry average minimum",
      valid: "Valid for 12 months",
      descList: [
        "Social media account setup and management",
        "Social media post scheduling and publishing",
        "Social media advertising management (including Facebook)",
        "Community management (responding to comments and messages)",
      ],
    },
    {
      title: "Revolutionary",
      price: "0",
      date: "Every month",
      desc: "Revolutionary pricing model",
      valid: "Valid for 12 months",
      descList: [
        "Social media account setup and management",
        "Content creation (including graphics, photos, and videos)",
        "Social media post scheduling and publishing",
        "Social media analytics and reporting",
        "Influencer outreach and management",
        "Community management (responding to comments and messages)",
        "Social media strategy development and implementation",
        "Social commerce management",
      ],
    },
    {
      title: "Corporate",
      price: "5000",
      date: "Every month",
      desc: "Industry average all inclusive plan",
      valid: "Valid for 12 months",
      descList: [
        "Social media account setup and management",
        "Content creation (including graphics, photos, and videos)",
        "Social media analytics and reporting",
        "Social media advertising management (including Facebook and",
        "Influencer outreach and management",
        "Community management (responding to comments and messages)",
        "Social media strategy development and implementation",
      ],
    },
  ];
  const showPopup = (item) => {
    setSubject(`Price (${item})`);
    setClosePopup(true);
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header
        setMenu={setMenu}
        setClosePopup={setClosePopup}
        activeTitle={"Pricing"}
        setSubject={setSubject}
      />
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={subject} />
      )}
      {menu && <MobileMenu setMenu={setMenu} />}
      <div className="price_header">Our pricing plans</div>
      <div className="price_header-desc">
        Pixomnia offers an affordable and flexible pricing structure that can
        accommodate restaurants of all sizes. At Pixomnia, we have a
        revolutionary pricing model.
      </div>
      <div className="price_data-cont">
        <div className="price_data-side-cont">
          <div className="price_data-side-header-cont">
            <div className="price_data-side-header">{data[0].title}</div>
            <div className="price_data-side-header-price">
              <div className="price_data-side-header-price-icon">$</div>
              {data[0].price}
              <span className="price_data-side-header-price-mo"> / mo</span>
            </div>
            <div className="price_data-side-header-desc">{data[0].desc}</div>
            <div className="price_data-side-header-valid">{data[0].valid}</div>
            <div
              className="price_data-side-header-btn"
              onClick={() => showPopup(data[0].title)}
            >
              Select
            </div>
          </div>
          <div className="pice_data-bottom-cont">
            {data[0].descList.map((item, key) => (
              <div key={key} className="price_item-cont">
                <img
                  alt="confirm"
                  src={priceConfirm}
                  className="price_item-img"
                />
                <div className="price_item-text">{item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="price_data-center-cont">
          <div className="price_data-center-header-cont">
            <div className="price_star-cont">
              <img alt="star" src={star} className="price_star-img" />
              <img alt="star" src={star} className="price_star-img" />
              <img alt="star" src={star} className="price_star-img" />
            </div>
            <div className="price_data-ceter-header-text">
              Great Starting Point
            </div>
            <div className="price_data-ceter-header">{data[1].title}</div>
            <div className="price_data-side-header-price">
              <div className="price_data-side-header-price-icon">$</div>
              {data[1].price}
              <span className="price_data-side-header-price-mo"> / mo</span>
            </div>
            <div className="price_data-side-header-desc">{data[1].desc}</div>
            <div className="price_data-side-header-valid">{data[1].valid}</div>
            <div
              className="price_data-side-header-btn"
              onClick={() => showPopup(data[1].title)}
            >
              Select
            </div>
          </div>
          <div className="pice_data-bottom-cont">
            {data[1].descList.map((item, key) => (
              <div key={key} className="price_item-cont">
                <img
                  alt="confirm"
                  src={priceConfirm}
                  className="price_item-img"
                />
                <div className="price_item-text">{item}</div>
              </div>
            ))}
          </div>
        </div>
        <div className="price_data-side-cont">
          <div className="price_data-side-header-cont">
            <div className="price_data-side-header">{data[2].title}</div>
            <div className="price_data-side-header-price">
              <div className="price_data-side-header-price-icon">$</div>
              {data[2].price}
              <span className="price_data-side-header-price-mo"> / mo</span>
            </div>
            <div className="price_data-side-header-desc">{data[2].desc}</div>
            <div className="price_data-side-header-valid">{data[2].valid}</div>
            <div
              className="price_data-side-header-btn"
              onClick={() => showPopup(data[2].title)}
            >
              Select
            </div>
          </div>
          <div className="pice_data-bottom-cont">
            {data[2].descList.map((item, key) => (
              <div key={key} className="price_item-cont">
                <img
                  alt="confirm"
                  src={priceConfirm}
                  className="price_item-img"
                />
                <div className="price_item-text">{item}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Price;
