import React, { useState } from "react";
import "./PopupContacts.css";
import close from "../../assets/icons/closeBlack.svg";
import axios from "axios";
import Loader from "../utils/Loader/Loader";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShowSend } from "../../redux/reducer";
import required from "../../assets/icons/required.jpeg";

const PopupContacts = ({ setClosePopup, subject }) => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [firstName, setFirtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setComapuName] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();

  const closePopup = () => {
    const param = searchParams.get("contact");
    if (param) {
      searchParams.delete("contact");
      setSearchParams(searchParams);
    }
    setClosePopup(false);
  };
  const postMessages = (event) => {
    event.preventDefault();
    setLoader(true);
    const mess = `First name: ${firstName},\nLast name: ${lastName},\nCompany name: ${companyName},\nJob title: ${jobTitle},\nemail: ${email},\nphone: ${phone},\nmessage: ${message}`;
    const subj = subject;
    axios
      .post(
        "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmail",
        {
          message: mess,
          subject: subj,
        }
      )
      .then((data) => {
        const subjc = "Thank you for your request";
        const messa = `Dear ${firstName},\nThank you for reaching out to us and expressing interest in our services. \n\nWe greatly appreciate your inquiry. This email is to confirm that we have received your request, and we are thrilled to assist you. \nOur dedicated team member will review your message promptly and provide a response within 24 hours.\nShould you have any additional questions or require further assistance, please feel free to reach out to us. \nWe are here to help.Best regards,\n\nPixomnia Marketing Team\ninfo@pixomnia.io\n(866) 899-2588`;
        const html = `<!DOCTYPE html>
        <html>

        <head>
            <meta name="format-detection" content="telephone=yes">
        </head>
        
        <body>
            <div style="font-weight: 500; color: #000;">
                Dear ${firstName},<br />Thank you for reaching out to us and expressing interest in our services. <br /><br />We
                greatly appreciate your inquiry. This email is to confirm that<strong> we have received your request,</strong>
                and we are thrilled
                to assist you. <br />Our dedicated team member will review your message promptly and provide a response within
                24
                hours.<br />Should you have any additional questions or require further assistance, please feel free to reach
                out
                to us.<br /> We are here to help.<br /><br />Best regards,</div>Pixomnia Marketing Team<br />
            info@pixomnia.io<br />+1(800)
            657-0107
            </div>
        </body>
        
        </html>`;
        axios
          .post(
            "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmailToUser",
            {
              message: messa,
              subject: subjc,
              to: email,
              html: html,
            }
          )
          .then((datas) => console.log(datas));
        setLoader(false);
        dispatch(setShowSend(true));
        closePopup();
      })
      .catch((err) => alert(err));
  };
  return (
    <div className="popup-contacts_cont">
      <form onSubmit={postMessages}>
        <div className="popup-contacts_header-cont">
          <div className="popup-contacts_header">Contact us for a demo</div>
          <div className="popup-contacts_header-close">
            <img
              src={close}
              alt="close"
              className="popup-contacts_header-close-img"
              onClick={() => closePopup()}
            />
          </div>
        </div>
        <div className="popup-contacts_inputs-cont">
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <img
                src={required}
                className="popup-contacts_input-req-img"
                alt="req"
              />
              <div className="popup-contacts_input-header">First Name</div>
            </div>
            <input
              className="popup-contacts_input"
              value={firstName}
              onChange={(e) => setFirtName(e.target.value)}
              required={true}
            />
          </div>
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <div className="popup-contacts_input-header">Last Name</div>
            </div>
            <input
              className="popup-contacts_input"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="popup-contacts_inputs-cont">
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <div className="popup-contacts_input-header">Company name</div>
            </div>
            <input
              className="popup-contacts_input"
              value={companyName}
              onChange={(e) => setComapuName(e.target.value)}
            />
          </div>
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <div className="popup-contacts_input-header">Job title</div>
            </div>
            <input
              className="popup-contacts_input"
              value={jobTitle}
              onChange={(e) => setJobTitle(e.target.value)}
            />
          </div>
        </div>
        <div className="popup-contacts_inputs-cont">
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <img
                src={required}
                className="popup-contacts_input-req-img"
                alt="req"
              />
              <div className="popup-contacts_input-header">Email</div>
            </div>
            <input
              className="popup-contacts_input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required={true}
            />
          </div>
          <div className="popup-contacts_input-cont">
            <div className="popup-contacts_input-cont-req">
              <img
                src={required}
                className="popup-contacts_input-req-img"
                alt="req"
              />
              <div className="popup-contacts_input-header">Phone</div>
            </div>
            <input
              className="popup-contacts_input"
              type="number"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required={true}
            />
          </div>
        </div>
        <div className="popup-contacts_input-cont message">
          <div className="popup-contacts_input-cont-req">
            <img
              src={required}
              className="popup-contacts_input-req-img"
              alt="req"
            />
            <div className="popup-contacts_input-header message">
              Leave us a message...
            </div>
          </div>
          <textarea
            className="popup-contacts_input message"
            aria-multiline="true"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required={true}
          />
        </div>
        <div className="popup-contacts_btn-cont">
          <button className="popup-contacts_btn">
            {loader ? <Loader /> : "Submit"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PopupContacts;
