import React, { useState } from "react";
import "./Features.css";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";
import MobileMenu from "../MobileMenu/MobileMenu";
import img1 from "../../assets/icons/futer_img.png";
import img2 from "../../assets/icons/futer_img2.svg";
import img3 from "../../assets/icons/futer_img3.svg";
import img4 from "../../assets/icons/futer_img4.svg";
import img5 from "../../assets/icons/futers_img5.svg";
import img6 from "../../assets/icons/futer_img6.svg";
import PopupContacts from "../PopupContacts/PopupContacts";

const Features = (props) => {
  const [menu, setMenu] = useState(false);
  const [popupClose, setClosePopup] = useState(false);
  const [subject, setSubject] = useState(false);
  const [data, setdata] = useState([
    {
      img: img1,
      header: "Social media content creation",
      desc: "We work with you to create a content plan that aligns with your brand and marketing objectives. Our team of experienced content creators produces high-quality visuals, including photos, videos, and graphics, that showcase your brand in the best possible light. We also help with caption writing, hashtag research, and social media scheduling.",
      width: 120,
      active: false,
    },
    {
      img: img2,
      header: "Complimentary photo souvenir for your visitors",
      desc: "We offer a complimentary photo souvenir service that adds an extra touch of personalization to your guests' dining experience. We take photos of your guests and provide them with a printed and digital copy to take home as a memento of their visit.",
      width: 90,
      active: false,
    },
    {
      img: img3,
      header: "Indoor promotion and customer loyalty program",
      desc: "Our program offers a unique and effective way to reward repeat customers while promoting your brand and current promotions. Complimentary postcards with a unique QR code lead customers to an online album page, designed in your corporate style, featuring information on promotions and pre-sale solutions like gift cards and limited-time offers. We partner with top-notch services like Groupon, Square, and Yelp to ensure the highest quality solutions. By using our program, you can enhance customer experience and increase brand awareness simultaneously.",
      width: 120,
      active: false,
    },
    {
      img: img4,
      header: "Social media marketing program",
      desc: "We offer a social media marketing program that helps you reach a wider audience and drive traffic to your establishment. Our team of experts creates targeted social media campaigns that align with your marketing objectives and budget.",
      width: 70,
      active: false,
    },

    {
      img: img5,
      header: "Social commerce program",
      desc: "We help you leverage the power of social media to increase your online sales through our social commerce program. Our team sets up and manages your social commerce channels, including Instagram shopping, Facebook shop, and Google shopping.",
      width: 120,
      active: false,
    },
    {
      img: img6,
      header: "Smart advetisement budgeting",
      desc: "At Pixomnia, we understand the importance of effective advertising within a limited budget. That's why we offer our Smart Advertisement Budgeting program, which helps restaurants optimize their advertising spend for maximum ROI. Our team conducts a thorough analysis of your target audience and creates a targeted advertising strategy tailored to your specific needs.",
      width: 95,
      active: false,
    },
  ]);

  const changeActive = (index) => {
    let newdata = [...data];
    if (newdata[index].active) {
      newdata[index].active = false;
    } else {
      newdata[index].active = true;
    }
    setdata(newdata);
  };

  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header
        setMenu={setMenu}
        activeTitle={"Features"}
        setClosePopup={setClosePopup}
        setSubject={setSubject}
      />
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={subject} />
      )}
      {menu && <MobileMenu setMenu={setMenu} />}
      <div className="features_header">Our Features</div>
      <div className="features_description">
        Pixomnia offers a range of services to help businesses enhance their
        digital presence through high-quality visual content. Our services are
        tailored to meet the unique needs of each client, and we work closely
        with you to ensure that we understand your vision and goals. Here's a
        detailed explanation of how Pixomnia's services work and what we offer:
      </div>
      <div className="features_data-cont">
        {data.map((item, key) => (
          <div
            className={
              item.active
                ? "features_data-item-cont active"
                : "features_data-item-cont"
            }
            key={key}
          >
            <div
              style={{
                width: "100%",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                alt={item.img}
                src={item.img}
                style={{ position: "absolute", width: item.width, top: -50 }}
              />
            </div>
            {!item.active && <div className="features_gradient-block"></div>}

            <div className="features_data-item-header">{item.header}</div>
            <div
              className={
                item.active
                  ? "features_data-item-desc active"
                  : "features_data-item-desc"
              }
            >
              {item.desc}
            </div>
            <div
              className="features_data-item-more"
              onClick={() => changeActive(key)}
            >
              {item.active ? "Close" : "Learn more"}
            </div>
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
};

export default Features;
