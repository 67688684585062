import React, { useEffect, useState } from "react";
import "./Landing.css";
import Header from "./Header";
import VideoCont from "./VideoCont";
import Slider from "./Slider";
import ActiveBtnBlock from "./AtiveBtnBlock";
import Footer from "./Footer";
import message from "../../assets/icons/messag.webp";
import call from "../../assets/icons/call.webp";
import close from "../../assets/icons/cloce.svg";
import PopupContacts from "../PopupContacts/PopupContacts";
import MobileMenu from "../MobileMenu/MobileMenu";
import ContactCont from "./ContactCont";
import { useSearchParams } from "react-router-dom";

const Landing = (props) => {
  const callHandler = () => {
    window.open("tel:+18668992588");
  };
  const smsHandler = () => {
    window.open("sms:+18668992588");
  };
  const [showPopup, setShowPopup] = useState(true);
  const [popupClose, setClosePopup] = useState(false);
  const [menu, setMenu] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const param = searchParams.get("contact");
    if (param) {
      setClosePopup(true);
    }
  }, [setClosePopup]);

  return (
    <div className="landing-cont">
      <div className="landing_ban_contacts-cont">
        {showPopup && (
          <>
            <div
              className="landing_ban_contacts-close-cont"
              onClick={() => setShowPopup(false)}
            >
              <img
                className="landing_ban_contacts-close"
                alt="close"
                src={close}
              />
            </div>

            <div className="landing_ban_contacts-text-block">
              <div className="landing_ban_contacts-text">
                Speak with a real person, call or text now.
              </div>
            </div>
          </>
        )}

        <div className="landing_ban_contacts-img-block">
          <div className="landing_ban_contacts-img-cont" onClick={callHandler}>
            <img className="landing_ban_contacts-img" alt="phone" src={call} />
          </div>
          <div
            className="landing_ban_contacts-img-cont right"
            onClick={smsHandler}
          >
            <img
              className="landing_ban_contacts-img"
              alt="message"
              src={message}
            />
          </div>
        </div>
      </div>
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={"Book a demo"} />
      )}
      {menu && <MobileMenu setMenu={setMenu} />}
      <Header setClosePopup={setClosePopup} setMenu={setMenu} />
      <VideoCont setClosePopup={setClosePopup} />
      <ContactCont />
      <Slider />
      <ActiveBtnBlock setClosePopup={setClosePopup} />
      <Footer />
    </div>
  );
};

export default Landing;
