import React from "react";
import "./Header.css";
import logo from "../../assets/icons/logoFooter.webp";

import { Link } from "react-router-dom";
import burger from "../../assets/icons/burger.svg";

const Header = ({ setMenu, activeTitle, setClosePopup, setSubject }) => {
  const data = [
    { title: "Features", active: "false", link: "/features" },
    { title: "Pricing", active: "false", link: "/pricing" },
    { title: "Jobs", active: "false", link: "/jobs" },
    { title: "Contact", active: "false", link: "/contacts" },
  ];
  const showPopup = () => {
    setSubject("getStarted");
    setClosePopup(true);
  };
  return (
    <header className="header_cont">
      <div className="header_content-cont">
        <Link className="header_logo-cont" to="/">
          <img src={logo} alt="logo" className="header_logo" />
        </Link>
        <div onClick={() => setMenu(true)} className="header_burger-cont">
          <img src={burger} alt="burger" className="header_burger" />
        </div>

        <div className="header_item-cont">
          {data.map((item, key) => (
            <Link
              key={key}
              className={
                activeTitle === item.title
                  ? "header_item active"
                  : "header_item"
              }
              to={item.link}
            >
              {item.title}
            </Link>
          ))}
          {/* <Link className="header_item" to="/features">
            Features
          </Link>
          <Link className="header_item" to="/price">
            Pricing
          </Link>
          <Link className="header_item" to="/jobs">
            Jobs
          </Link>
          <Link className="header_item" to="/contacts">
            Contact
          </Link> */}
        </div>
        <div className="header_login-cont">
          <div className="header_login-text">Log In / registration</div>
          <div className="header_line"></div>
          <div className="header_button" onClick={showPopup}>
            Get started
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
