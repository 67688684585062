import React, { useState } from "react";
import "./Footer.css";
import logoFooter from "../../assets/icons/logoFooter.webp";
import inst from "../../assets/icons/inst.svg";
import fb from "../../assets/icons/fb.svg";
import twitter from "../../assets/icons/twitter.svg";
import ticktok from "../../assets/icons/ticktok.svg";
import youtube from "../../assets/icons/youtube.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = (props) => {
  const navigation = useNavigate();
  const toLink = (link) => {
    navigation(`/${link}`);
  };
  function toUrl(url) {
    window.location.replace(url);
  }
  const [email, setEmail] = useState();
  const postMessages = () => {
    const mess = `\nemail: ${email},`;
    axios
      .post(
        "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmail",
        {
          message: mess,
          subject: "Subscribe",
        }
      )
      .then((data) => {
        console.log(data);
      })
      .catch((err) => alert(err));
  };
  return (
    <div className="footer_cont">
      <div className="footer_header-cont">
        <div className="footet_header-input-cont">
          <div className="footet_header-input-header">
            Subscribe to our newsletter
          </div>
          <div className="footet_header-input-block">
            <input
              className="footet_header-input"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="footet_header-input-btn" onClick={postMessages}>
              Subscribe
            </div>
          </div>
        </div>
        <div className="footer_header-desc">
          We're looking for talented, passionate folks to join our team.
        </div>
        <div className="footer_header-line"></div>
        <div className="footer_header-link-cont">
          <div className="footer_header-link" onClick={() => toLink("jobs")}>
            Jobs at Pixomnia
          </div>{" "}
          <div
            className="footer_header-link"
            onClick={() => toLink("contacts")}
          >
            About Pixomnia
          </div>
        </div>
      </div>
      <div className="footer_social-media-bar-mob">
        <div className="footer_social-media-bar-cont-mob">
          <img
            alt="soc"
            className="footer_social-img"
            src={inst}
            onClick={() => toUrl("https://www.instagram.com/pixomniainc/")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={ticktok}
            onClick={() => toUrl("https://www.tiktok.com/@pixomnia")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={twitter}
            onClick={() => toUrl("https://twitter.com/pixomniapp")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={youtube}
            onClick={() => toUrl("https://www.youtube.com/@pixomnia")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={fb}
            onClick={() => toUrl("https://www.facebook.com/pixomniainc")}
          />
        </div>
      </div>
      <div className="footer_middle-cont">
        <div>
          <img
            src={logoFooter}
            className="footer_middle-logo"
            alt="footer-logo"
          />
        </div>
        <div className="footer_middle-links-cont">
          <div className="footer_middle-links-blok mob">
            <div
              className="footer_middle-link"
              onClick={() => toLink("features")}
            >
              Features
            </div>
            <div className="footer_middle-link" onClick={() => toLink("price")}>
              Pricing
            </div>
            <div
              className="footer_middle-link"
              onClick={() => toLink("contacts")}
            >
              Contact
            </div>
          </div>
          <div className="footer_middle-links-blok">
            <div
              className="footer_middle-link"
              onClick={() => toLink("privacy")}
            >
              Privacy Notice
            </div>
            <div className="footer_middle-link" onClick={() => toLink("terms")}>
              Terms of Service
            </div>
          </div>
          <div className="footer_middle-links-blok">
            <div className="footer_middle-text">2811 Cahuenga Blvd W,</div>
            <div className="footer_middle-text">Los Angeles, CA 90068</div>
            <div style={{ marginLeft: 8, marginTop: 8 }}>
              <a href="mailto:info@pixomnia.com" className="footer_middle-text">
                info@pixomnia.com
              </a>
            </div>
            <div style={{ marginLeft: 4 }}>
              <a
                href="tel:+1 (866) 899-2588"
                className="footer_middle-text mob"
              >
                +1 (866) 899-2588
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer_social-media-cont">
        <div className="footer_social-burn">© 2023 by Pixomnia inc.</div>
        <div className="footer_social-cont">
          <img
            alt="soc"
            className="footer_social-img"
            src={inst}
            onClick={() => toUrl("https://www.instagram.com/pixomniainc/")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={ticktok}
            onClick={() => toUrl("https://www.tiktok.com/@pixomnia")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={twitter}
            onClick={() => toUrl("https://twitter.com/pixomniapp")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={youtube}
            onClick={() => toUrl("https://www.youtube.com/@pixomnia")}
          />
          <img
            alt="soc"
            className="footer_social-img"
            src={fb}
            onClick={() => toUrl("https://www.facebook.com/pixomniainc")}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
