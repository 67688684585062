import React, { useRef, useState } from "react";
import "./Jobs.css";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";
import job from "../../assets/icons/jobs.webp";
import MobileMenu from "../MobileMenu/MobileMenu";
import PopupContacts from "../PopupContacts/PopupContacts";
import axios from "axios";
import Loader from "../utils/Loader/Loader";

const Jobs = (props) => {
  const [menu, setMenu] = useState(false);
  const [popupClose, setClosePopup] = useState(false);
  const [selectValue, setSelectvalue] = useState("");
  const [firstName, setFirtName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [availableDate, setAlilableDate] = useState("");
  const [linkRedume, setLinkResume] = useState("");
  const [subject, setSubject] = useState(false);
  const [loader, setLoader] = useState(false);
  const targetRef = useRef(null);
  const handleClick = (vacancy) => {
    if (targetRef.current) {
      setSelectvalue(vacancy);
      window.scrollTo({
        top: targetRef.current.offsetTop - 100,
        behavior: "smooth",
      });
    }
  };
  const data = [
    {
      vacancy: "On-site Content Creator",
      desc: [
        "As an On-Site Content Creator, you will be responsible for producing high-quality visual content for our clients' social media platforms. You will work closely with our clients and our social media marketing team to develop content that is on-brand and engaging.",
        "Enjoy the benefits of app-based employment, which means you have the flexibility to create your own schedule and work when it's convenient for you. In addition, you'll receive weekly pay and bonuses based on your performance, giving you the opportunity to earn even more as you excel in your role. Join our team today and enjoy the freedom and rewards of working with Pixomnia.",
      ],
      req: [
        "Strong photography and videography skills.",
        "Excellent communication and customer service skills.",
        "Ability to work independently",
        "A high-quality iPhone 13 or newer model for capturing content on-site",
        "Familiarity with the restaurant industry and its social media trends",
        "Ability to work flexible hours, including night shifts",
      ],
    },
    {
      vacancy: "Event Pro Content Creator",
      desc: [
        "The Event Pro Content Creator will be responsible for capturing the essence of our clients' events through photography and videography, as well as creating compelling content for use in marketing materials and social media platforms. This person will work closely with our marketing team to ensure that our clients' events are well-documented and promoted effectively.",
      ],
      req: [
        "Bachelor's degree in photography, graphic design, or a related field.",
        "2+ years of experience in content creation for social media platforms.",
        "Proficiency in Adobe Creative Suite and other relevant software.",
        "Strong photography and videography skills.",
        "Excellent communication and collaboration skills.",
        "Ability to work independently and manage multiple projects simultaneously.",
      ],
    },
  ];
  const postMessages = () => {
    setLoader(true);
    const mess = `First name: ${firstName},\nLast name: ${lastName},\nemail: ${email},\nphone: ${phone},\nAvailable date: ${availableDate},\nResume link ${linkRedume}`;
    axios
      .post(
        "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmail",
        {
          message: mess,
          subject: `Job (${selectValue})`,
        }
      )
      .then((data) => {
        setLoader(false);
      })
      .catch((err) => alert(err));
  };
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <Header
        setMenu={setMenu}
        activeTitle={"Jobs"}
        setClosePopup={setClosePopup}
        setSubject={setSubject}
      />
      {popupClose && (
        <PopupContacts setClosePopup={setClosePopup} subject={subject} />
      )}
      {menu && <MobileMenu setMenu={setMenu} />}
      <div className="job_header">Work at Pixomnia</div>
      <div className="job_desc">
        There are always opportunities for
        <br /> talented people to join our epic team{" "}
      </div>
      <div className="job_text">
        We are always on the lookout for talented individuals who are passionate
        about creativity, innovation, and customer satisfaction. If you have a
        knack for problem-solving, a flair for creativity, and a desire to join
        an epic team of professionals, then you've come to the right place.
        <br />
        <br />​ Whether you're an experienced professional or just starting your
        career, we have opportunities that can help you grow and reach your full
        potential. So, if you're looking for a challenging yet rewarding career
        in a dynamic industry, come and join us at Pixomnia.
      </div>
      <img className="job_img" src={job} alt="job" />

      <div className="job_vacancies-header">Open positions at Pixomnia</div>
      <div className="job_vacancies-block">
        {data.map((item, key) => (
          <div className="job_vacancies-cont-block" key={key}>
            <div className="job_vacancies-left-block">
              <div className="job_vacancies-left-header">{item.vacancy}</div>
              {item.desc.map((i, k) => (
                <div className="job_vacancies-left-item" key={k}>
                  {i}
                </div>
              ))}
            </div>
            <div className="job_vacancies-right-block">
              <div className="job_vacancies-right-header">Requirements</div>
              <div className="job_vacancies-right-items">
                {item.req.map((i, k) => (
                  <div className="job_vacancies-right-item-block" key={k}>
                    <div className="job_vacancies-right-item-dot"></div>
                    <div className="job_vacancies-right-item">{i}</div>
                  </div>
                ))}
              </div>
              <div
                className="job_vacancies-right-btn"
                onClick={() => handleClick(item.vacancy)}
              >
                Apply
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="job_inputs-header" ref={targetRef}>
        Think you have what it takes?
      </div>
      <div className="job_inputs-desc">Send us your resume</div>
      <div className="job_inputs-cont">
        <div className="job_input-cont">
          <div className="job_input-placeholder">First name</div>
          <input
            className="job_input"
            aria-label="firstname"
            value={firstName}
            onChange={(e) => setFirtName(e.target.value)}
          />
        </div>
        <div className="job_input-cont">
          <div className="job_input-placeholder">Last name</div>
          <input
            className="job_input"
            aria-label="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="job_inputs-cont">
        <div className="job_input-cont">
          <div className="job_input-placeholder">Email</div>
          <input
            className="job_input"
            aria-label="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="job_input-cont">
          <div className="job_input-placeholder">Phone</div>
          <input
            className="job_input"
            aria-label="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>
      </div>
      <div className="job_inputs-cont">
        <div className="job_input-cont">
          <div className="job_input-placeholder">
            Position you are applying to
          </div>
          <select
            className="job_input"
            value={selectValue}
            onChange={(e) => setSelectvalue(e.target.value)}
          >
            <option>On-site Content Creator</option>
            <option>Event Pro Content Creator</option>
          </select>
          {/* <input className="job_input" aria-label="position" /> */}
        </div>
        <div className="job_input-cont">
          <div className="job_input-placeholder">Available start date</div>
          <input
            className="job_input"
            aria-label="available"
            value={availableDate}
            onChange={(e) => setAlilableDate(e.target.value)}
          />
        </div>
      </div>
      <div className="jobe_resume-header">Link to your Resume</div>
      <div className="jobe_resume-cont">
        <input
          className="jobe_resume-input"
          aria-label="resume"
          value={linkRedume}
          onChange={(e) => setLinkResume(e.target.value)}
        />
        <div className="job_resume-btn" onClick={postMessages}>
          {loader ? <Loader /> : "Submit"}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Jobs;
