import React, { useState } from "react";
import logo from "../../assets/icons/logoFooter.webp";
import phone from "../../assets/icons/phone.svg";
import mail from "../../assets/icons/mail.svg";
import location from "../../assets/icons/location.svg";
import inst from "../../assets/icons/inst.svg";
import fb from "../../assets/icons/fb.svg";
import twitter from "../../assets/icons/twitter.svg";
import ticktok from "../../assets/icons/ticktok.svg";
import youtube from "../../assets/icons/youtube.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import LoaderBlack from "../utils/LoaderBlack/LoaderBlack";
import { useDispatch } from "react-redux";
import { setShowSend } from "../../redux/reducer";

const Footer = (props) => {
  function toUrl(url) {
    window.location.replace(url);
  }
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const dispatch = useDispatch();

  const postMessages = (event) => {
    event.preventDefault();
    setLoader(true);
    const mess = `Name: ${name},\nemail: ${email},\nQuestion: ${desc}`;
    const subj = "Question";
    axios
      .post(
        "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendQuestion",
        {
          message: mess,
          subject: subj,
        }
      )
      .then((data) => {
        const subjc = "Thank you for your request";
        const messa = `Dear ${name},\nThank you for reaching out to us and expressing interest in our services. \n\nWe greatly appreciate your inquiry. This email is to confirm that we have received your request, and we are thrilled to assist you. \nOur dedicated team member will review your message promptly and provide a response within 24 hours.\nShould you have any additional questions or require further assistance, please feel free to reach out to us. \nWe are here to help.Best regards,\n\nPixomnia Marketing Team\ninfo@pixomnia.io\n(866) 899-2588`;
        const html = `<!DOCTYPE html>
        <html>

        <head>
            <meta name="format-detection" content="telephone=yes">
        </head>
        
        <body>
            <div style="font-weight: 500; color: #000;">
                Dear ${name},<br />Thank you for reaching out to us and expressing interest in our services. <br /><br />We
                greatly appreciate your inquiry. This email is to confirm that<strong> we have received your request,</strong>
                and we are thrilled
                to assist you. <br />Our dedicated team member will review your message promptly and provide a response within
                24
                hours.<br />Should you have any additional questions or require further assistance, please feel free to reach
                out
                to us.<br /> We are here to help.<br /><br />Best regards,</div>Pixomnia Marketing Team<br />
            info@pixomnia.io<br />+1(800)
            657-0107
            </div>
        </body>
        
        </html>`;

        axios
          .post(
            "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/sendEmailToUser",
            {
              message: messa,
              subject: subjc,
              to: email,
              html: html,
            }
          )
          .then((datas) => console.log(datas));
        setName("");
        setEmail("");
        setDesc("");
        setLoader(false);
        dispatch(setShowSend(true));
      })
      .catch((err) => alert(err));
  };
  return (
    <div className="landing_footer">
      <div className="landing_footer-cont">
        <div className="landing_footer-left">
          <img src={logo} alt="logo" className="landing_footer-left-img" />
          <div className="landing_footer-left-privacy-block">
            <div>
              <Link className="landing_footer-left-privacy-text" to="/privacy">
                Privacy Notice
              </Link>
            </div>
            <div>
              <Link className="landing_footer-left-privacy-text" to="/terms">
                Terms of Service
              </Link>
            </div>

            <div></div>
          </div>
          <div className="landing_footer-left-contact-block">
            <div className="landing_footer-left-contact-cont">
              <img
                alt="logo"
                src={location}
                className="landing_footer-left-contact-img"
              />
              <div className="landing_footer-left-contact-text">
                2811 Cahuenga Blvd W, Los Angeles, CA 90068
              </div>
            </div>
            <div className="landing_footer-left-contact-cont">
              <img
                alt="logo"
                src={mail}
                className="landing_footer-left-contact-img"
              />
              <div className="landing_footer-left-contact-text">
                info@pixomnia.io
              </div>
            </div>
            <div className="landing_footer-left-contact-cont">
              <img
                alt="logo"
                src={phone}
                className="landing_footer-left-contact-img"
              />
              <div className="landing_footer-left-contact-text">
                (866) 899-2588
              </div>
            </div>
          </div>
        </div>
        <div className="landing_footer-right">
          <div className="landing_footer-right-header">have questions?</div>
          <form onSubmit={postMessages}>
            <div className="landing_footer-right-inputs">
              <div className="landing_footer-right-input-cont">
                <input
                  className="landing_footer-right-input"
                  placeholder="Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  required={true}
                />
              </div>
              <div className="landing_footer-right-input-cont">
                <input
                  className="landing_footer-right-input right"
                  placeholder="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required={true}
                  type="email"
                />
              </div>
            </div>
            <div className="landing_footer-right-textarea-cont">
              <textarea
                className="landing_footer-right-textarea"
                placeholder="Your question"
                onChange={(e) => setDesc(e.target.value)}
                value={desc}
                required={true}
              />
            </div>
            <button type="submit" className="landing_footer-right-btn">
              {loader ? <LoaderBlack /> : "Submit"}
            </button>

            {/* {} */}
          </form>
        </div>
      </div>
      <div className="footer-landing-line"></div>
      <div className="footer-landing-bottom-cont">
        <div className="footer-landing-bottom-cont-block">
          <div className="footer_social-burn landing">
            © 2023 by Pixomnia inc.
          </div>
          <div className="footer_social-cont mob">
            <img
              alt="soc"
              className="footer_social-img"
              src={inst}
              onClick={() => toUrl("https://www.instagram.com/pixomniainc/")}
            />
            <img
              alt="soc"
              className="footer_social-img"
              src={ticktok}
              onClick={() => toUrl("https://www.tiktok.com/@pixomnia")}
            />
            <img
              alt="soc"
              className="footer_social-img"
              src={twitter}
              onClick={() => toUrl("https://twitter.com/pixomniapp")}
            />
            <img
              alt="soc"
              className="footer_social-img"
              src={youtube}
              onClick={() => toUrl("https://www.youtube.com/@pixomnia")}
            />
            <img
              alt="soc"
              className="footer_social-img"
              src={fb}
              onClick={() => toUrl("https://www.facebook.com/pixomniainc")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
