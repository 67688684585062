import React from "react";

const ActiveBtnBlock = ({ setClosePopup }) => {
  return (
    <div className="landing_active-btn-bloc">
      <div className="landing_active-btn-cont">
        <div className="landing_active-btn-flex">
          <div>
            <div className="landing_slider-header active_block">
              Unleash your marketing potential
            </div>
            <div className="landing_slider-desc active_block">
              WITH PIXOMNIA
            </div>
          </div>
          <div
            className="landing_active-btn"
            onClick={() => setClosePopup(true)}
          >
            BOOK DEMO
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActiveBtnBlock;
