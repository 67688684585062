import React from "react";
import "./MobileMenu.css";
import close from "../../assets/icons/closeW.svg";
import logo from "../../assets/icons/logoFooter.webp";
import { useNavigate } from "react-router-dom";

const MobileMenu = ({ setMenu }) => {
  const navigation = useNavigate();
  const changeLink = (link) => {
    setMenu(false);
    navigation(link);
  };
  return (
    <div className="mobile_cont">
      <header className="header_cont">
        <div className="header_content-cont">
          <div className="header_logo-cont" onClick={() => changeLink("/")}>
            <img src={logo} alt="logo" className="header_logo" />
          </div>
          <div onClick={() => setMenu(false)}>
            <img src={close} alt="burger" className="header_burger-close" />
          </div>
        </div>
      </header>
      <div className="mobile_link-cont">
        <div className="mobile_link">
          <a
            href="https://pixomniapromo.web.app"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#000" }}
          >
            Custom Web Apps
          </a>
        </div>
        <div className="mobile_link" onClick={() => changeLink("/features")}>
          More Features
        </div>
        {/* <div className="mobile_link" onClick={() => changeLink("/pricing")}>
          Pricing
        </div> */}
        <div className="mobile_link" onClick={() => changeLink("/jobs")}>
          Jobs
        </div>
        <div className="mobile_link" onClick={() => changeLink("/contacts")}>
          Contact
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
