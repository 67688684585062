import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";

const Terms = (props) => {
  return (
    <div>
      <Header />
      <div className="privacy">
        <div>Pixomnia Terms of Service</div>
        <div>
          These Terms of Service ("Terms") govern your access to and use of
          Pixomnia's website, mobile application, and services (collectively,
          the "Service"). Please read these Terms carefully before using the
          Service.
        </div>
        <div>Acceptance of Terms</div>
        <div>
          By accessing or using the Service, you agree to be bound by these
          Terms. If you do not agree to these Terms, you may not use the
          Service.
        </div>
        <div>Changes to Terms</div>
        <div>
          Pixomnia reserves the right to modify or update these Terms at any
          time, in its sole discretion. If we make material changes to these
          Terms, we will provide notice to you by email, posting a notice on the
          Service, or updating the "Last Updated" date at the top of these
          Terms. Your continued use of the Service after any such changes
          constitutes your acceptance of the new Terms. Use of Service
        </div>
        <div>
          You may use the Service only for lawful purposes and in accordance
          with these Terms. You agree not to use the Service:
        </div>
        <ul>
          <li>
            In any way that violates any applicable federal, state, local, or
            international law or regulation.
          </li>
          <li>
            To engage in any conduct that restricts or inhibits anyone's use or
            enjoyment of the Service, or which, as determined by Pixomnia, may
            harm Pixomnia or users of the Service, or expose them to liability.
          </li>
          <li>
            To use any robot, spider, or other automatic device, process, or
            means to access the Service for any purpose, including monitoring or
            copying any of the material on the Service.
          </li>
          <li>
            To use any manual process to monitor or copy any of the material on
            the Service or for any other unauthorized purpose without our prior
            written consent.
          </li>
          <li>
            To use any device, software, or routine that interferes with the
            proper working of the Service.
          </li>
          <li>
            To attempt to interfere with the Service in any way or circumvent
            any measures we may use to prevent or restrict access to the
            Service.
          </li>
          <li>
            To impersonate or attempt to impersonate Pixomnia, a Pixomnia
            employee, another user, or any other person or entity.
          </li>
          <li>
            To engage in any other conduct that restricts or inhibits anyone's
            use or enjoyment of the Service, or which, as determined by
            Pixomnia, may harm Pixomnia or users of the Service, or expose them
            to liability.
          </li>
        </ul>
        <div>Intellectual Property Rights</div>
        <div>
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by Pixomnia, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </div>
        <div>
          You may not copy, reproduce, modify, distribute, display, transmit,
          perform, publish, license, create derivative works from, transfer, or
          sell any information, software, products, or services obtained from
          the Service, except as expressly permitted by these Terms.
        </div>
        <div>Disclaimer of Warranties</div>
        <div>
          THE SERVICE IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS,
          WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
          INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.
          PIXOMNIA DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED OR
          ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SERVICE OR THE
          SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
          COMPONENTS.
        </div>
        <div>Limitation of Liability</div>
        <div>
          IN NO EVENT WILL PIXOMNIA, ITS AFFILIATES, LICENSORS, SERVICE
          PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR
          DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN
          CONNECTION WITH YOUR USE
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Terms;
