import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer.js/Footer";
import "./Privacy.css";

const Privacy = (props) => {
  return (
    <div>
      <Header />
      <div className="privacy">
        <div>Pixomnia Privacy Notice</div>
        <div>
          At Pixomnia, we are committed to protecting the privacy and personal
          information of our users. This Privacy Notice explains how we collect,
          use, and protect your personal data when you use our services.
        </div>
        <div>1.Information We Collect</div>
        <div>
          We collect the following types of personal data when you use our
          services:
        </div>
        <ul>
          <li>
            Information you provide us: This includes information you provide
            when you sign up for an account, fill out a form, or contact us for
            support. This information may include your name, email address,
            phone number, and payment information.
          </li>
          <li>
            Information we collect automatically: This includes information
            about your use of our services, such as your IP address, device
            information, and location information.
          </li>
          <li>
            Information we receive from third parties: This includes information
            we receive from social media platforms when you connect your account
            to our services.
          </li>
        </ul>
        <div>1.How We Use Your Information</div>
        <div>We use your personal data for the following purposes:</div>
        <ul>
          <li>
            To provide and improve our services: This includes creating and
            managing your account, processing payments, and providing customer
            support.
          </li>
          <li>
            To personalize your experience: This includes recommending content
            and features that may interest you based on your use of our
            services.
          </li>
          <li>
            To communicate with you: This includes sending you service-related
            notifications and promotional emails.
          </li>
          <li>
            To comply with legal obligations: This includes complying with
            applicable laws and regulations, responding to legal requests, and
            preventing fraudulent activity.
          </li>
        </ul>
        <div>1.How We Share Your Information</div>
        <div>
          We may share your personal data with the following types of third
          parties:
        </div>
        <ul>
          <li>
            Service providers: We may share your information with service
            providers who help us provide our services, such as payment
            processors, cloud storage providers, and customer support software
            providers.
          </li>
          <li>
            Advertising partners: We may share your information with advertising
            partners who help us promote our services.
          </li>
          <li>
            Legal authorities: We may share your information with law
            enforcement agencies, government officials, or other third parties
            when we are required to do so by law or in response to a subpoena or
            other legal request.
          </li>
        </ul>
        <div>1.Your Rights and Choices</div>
        <div>
          You have the following rights and choices regarding your personal
          data:
        </div>
        <ul>
          <li>
            Access: You have the right to request access to the personal data we
            hold about you.
          </li>
          <li>
            Correction: You have the right to request that we correct any
            inaccurate or incomplete personal data we hold about you.
          </li>
          <li>
            Deletion: You have the right to request that we delete your personal
            data in certain circumstances.
          </li>
          <li>
            Object: You have the right to object to the processing of your
            personal data in certain circumstances.
          </li>
          <li>
            Opt-out: You have the right to opt-out of receiving promotional
            emails from us by following the unsubscribe link in our emails.
          </li>
        </ul>
        <div>1.How We Protect Your Information</div>
        <div>
          We implement a variety of security measures to protect your personal
          data from unauthorized access, use, or disclosure. These measures
          include encryption, access controls, and regular security assessments.
        </div>
        <div>1.Changes to this Privacy Notice</div>
        <div>
          We may update this Privacy Notice from time to time to reflect changes
          in our services or legal requirements. We will notify you of any
          material changes to this Privacy Notice by posting a notice on our
          website or by sending you an email. If you have any questions or
          concerns about this Privacy Notice or our data processing practices,
          please contact us at privacy@pixomnia.com.
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
