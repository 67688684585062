import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Features from "./components/Features/Features";
import HomePage from "./components/HomePage/HomePage";
import Jobs from "./components/Jobs/Jobs";
import Price from "./components/Price/Price";
import Contacts from "./components/Contacts/Contacts";
import ScrollToTop from "./components/utils/ScrollToTop";
import Privacy from "./components/Privacy/Privacy";
import Terms from "./components/Privacy/Terms";
import Landing from "./components/Landing/Landing";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setShowSend } from "./redux/reducer";
import { dataShop } from "./processed_data";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App() {
  const { showSendPopup } = useSelector((state) => state.reducer);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setShowSend(false));
  };

  const post = () => {
    for (let i of dataShop) {
      axios
        .post(
          "https://stingray-app-lodnw.ondigitalocean.app/http://143.110.149.184/add_items_to_shop",
          {
            id_num: i.id_num,
            img: i.img,
            name: i.name,
            retailPrice: i.retailPrice,
            price: i.price,
            category: i.category,
          }
        )
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    }
    console.log(dataShop);
  };
  return (
    <div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={showSendPopup}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Thank you for your request, our dedicated team member will get back
            to you within 24 hours.
          </Alert>
        </Snackbar>
      </Stack>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/page" element={<HomePage />} />
          <Route path="/features" element={<Features />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/pricing" element={<Price />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
