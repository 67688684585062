import React, { useEffect, useRef } from "react";
import step1 from "../../assets/icons/step1.webp";
import step2 from "../../assets/icons/step2.webp";
import step3 from "../../assets/icons/step3.webp";
import step4 from "../../assets/icons/step4.webp";
import videoPopup from "../../assets/icons/slider.mp4";

const Slider = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const playVideo = () => {
      if (video.paused) {
        video.play().catch((error) => {
          // Обработка ошибки при воспроизведении
          console.error("Failed to play video:", error);
        });
      }
    };

    // Обрабатываем событие пользовательского взаимодействия (например, клик)
    const handleUserInteraction = () => {
      document.removeEventListener("click", handleUserInteraction);
      playVideo();
    };

    // Добавляем обработчик события пользовательского взаимодействия
    document.addEventListener("click", handleUserInteraction);

    // Очищаем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  }, []);

  return (
    <div className="landing_slider">
      <div className="landing_slider-header">HOW TO GET STARTED</div>
      <div className="landing_slider-desc">WITH PIXOMNIA</div>
      <div className="landing_slider-content-block">
        <div className="landing_slider-left-block">
          <div className="landing_slider-left-cont">
            <div className="landing_slider-left-text-block">
              <div className="landing_slider-left-text-title">Step 1</div>
              <div className="landing_slider-left-desc-desc">
                Book your free demo to reserve your spot.
              </div>
            </div>

            <img className="landing_slider-purple-img" src={step1} alt="step" />
          </div>
          <div className="landing_slider-left-cont">
            <div className="landing_slider-left-text-block">
              <div className="landing_slider-left-text-title">Step 3</div>
              <div className="landing_slider-left-desc-desc">
                Pro Content Session to produce high quality content for
                campaign.
              </div>
            </div>

            <img className="landing_slider-purple-img" src={step4} alt="step" />
          </div>
        </div>
        <div className="landing_slider-center-block">
          <video
            className="landing_slider-center-img"
            ref={videoRef}
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
            playsInline={true}
          >
            <source src={videoPopup} type="video/mp4" />
          </video>
        </div>

        <div className="landing_slider-right-block">
          <div className="landing_slider-right-cont">
            <img
              className="landing_slider-purple-img right"
              src={step2}
              alt="step"
            />

            <div className="landing_slider-right-text-block">
              <div className="landing_slider-right-text-title">Step 2</div>
              <div className="landing_slider-right-desc-desc">
                Meet with our team and discuss your goals for the duration of
                the demo.
              </div>
            </div>
          </div>
          <div className="landing_slider-right-cont">
            <img
              className="landing_slider-purple-img right"
              src={step3}
              alt="step"
            />
            <div className="landing_slider-right-text-block">
              <div className="landing_slider-right-text-title">Step 4</div>
              <div className="landing_slider-right-desc-desc">
                START to unleash your social media marketing potential.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="landing_slider-content-block-mob">
        <div className="landing_slider-left-block-mob">
          <div className="landing_slider-left-cont-mob">
            <div className="landing_slider-left-text-block-mob">
              <div className="landing_slider-left-text-title-mob">Step 1</div>
              <div className="landing_slider-left-desc-desc-mob">
                Book your free demo to reserve your spot.
              </div>
            </div>

            <img
              className="landing_slider-purple-img-mob"
              src={step1}
              alt="step"
            />
          </div>
          <div className="landing_slider-left-cont-mob">
            <div className="landing_slider-left-text-block-mob">
              <div className="landing_slider-left-text-title-mob">Step 2</div>
              <div className="landing_slider-left-desc-desc-mob">
                Meet with our team and discuss your goals for the duration of
                the demo.
              </div>
            </div>

            <img
              className="landing_slider-purple-img-mob"
              src={step2}
              alt="step-mob"
            />
          </div>
        </div>

        <div className="landing_slider-center-block-mob">
          <video
            className="landing_slider-center-img-mob"
            ref={videoRef}
            autoPlay={true}
            muted={true}
            loop={true}
            controls={false}
            playsInline={true}
          >
            <source src={videoPopup} type="video/mp4" />
          </video>
        </div>

        <div className="landing_slider-left-block-mob">
          <div className="landing_slider-left-cont-mob">
            <div className="landing_slider-left-text-block-mob">
              <div className="landing_slider-left-text-title-mob">Step 3</div>
              <div className="landing_slider-left-desc-desc-mob">
                Pro Content Session to produce high quality content for
                campaign.
              </div>
            </div>
            <img
              className="landing_slider-purple-img-mob"
              src={step4}
              alt="step"
            />
          </div>
          <div className="landing_slider-left-cont-mob">
            <div className="landing_slider-left-text-block-mob">
              <div className="landing_slider-left-text-title-mob">Step 4</div>
              <div className="landing_slider-left-desc-desc-mob">
                START to unleash your social media marketing potential.
              </div>
            </div>
            <img
              className="landing_slider-purple-img-mob"
              src={step3}
              alt="step"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;
